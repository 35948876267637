<template>
	<div class="search-panel" :class="type">
		<div class="search-panel__container search-panel-wrapper">
			<or-drop-down class="search-panel__item">
				<template v-slot:toggle="{ active }">
					<or-input
						readonly
						:placeholderWrap="$t('general.price')"
						icon-before="price"
						icon-after="arrow"
						v-model="displayPrice"
						class="search-panel__input main-type"
						:class="{ 'active-input': active, 'have-content': displayPrice }"
					/>
				</template>
				<template v-slot:content>
					<PriceBlock
						class="search-panel__drop-content"
						v-model="price"
						:range="priceRange"
					/>
				</template>
			</or-drop-down>

			<or-drop-down class="search-panel__item">
				<template v-slot:toggle="{ active }">
					<or-input
						readonly
						:placeholderWrap="$t('general.square')"
						:prefix="currentSquareSymbol"
						icon-after="arrow"
						v-model="displayArea"
						class="search-panel__input main-type"
						:class="{ 'active-input': active, 'have-content': displayArea }"
					/>
				</template>
				<template v-slot:content>
					<Square
						class="search-panel__drop-content"
						v-model="area"
						:range="areaRange"
					/>
				</template>
			</or-drop-down>

			<or-drop-down class="search-panel__item">
				<template v-slot:toggle="{ active }">
					<or-input
						readonly
						:placeholderWrap="$t('general.propertyType')"
						v-model="displayTypes"
						icon-before="property"
						icon-after="arrow"
						class="search-panel__input main-type placeholder-wrap"
						:class="{
							'active-textarea': active,
							'have-content': displayTypes.length
						}"
					/>
				</template>
				<template v-slot:content>
					<PropertyList
						class="search-panel__drop-content"
						:is-hidden-on-click="false"
						:values="filters.types"
						v-model="types"
					/>
				</template>
			</or-drop-down>

			<or-drop-down class="search-panel__item">
				<template v-slot:toggle="{ active }">
					<or-input
						readonly
						:placeholderWrap="$t('general.roomsBaths')"
						icon-before="rooms"
						v-model="displayRooms"
						icon-after="arrow"
						class="search-panel__input main-type placeholder-wrap"
						:class="{
							'active-textarea': active || displayRooms,
							'have-content': displayRooms
						}"
					/>
				</template>
				<template v-slot:content>
					<CountRoomsBaths
						class="search-panel__drop-content search-panel__drop-content--rooms-baths"
						:availableBathrooms="filters.bathrooms"
						:availableBedrooms="filters.bedrooms"
						v-model:bathrooms="bathrooms"
						v-model:bedrooms="bedrooms"
					/>
				</template>
			</or-drop-down>

			<or-button @click="submit" :height="48" class="search-panel-btn">
				{{ $t('general.search') }}
			</or-button>

			<or-drop-down class="search-panel__item" type="search">
				<template v-slot:toggle="{ active }">
					<or-input
						readonly
						:placeholderWrap="$t('general.project')"
						icon-before="location"
						icon-after="arrow"
						v-model="displayProjects"
						class="search-panel__input main-type"
						:class="{ 'active-input': active, 'have-content': displayProjects }"
					/>
				</template>
				<template v-slot:content>
					<WiseProjectsList
						class="search-panel__drop-content"
						:projects="filters.projects"
						v-model="projects"
					/>
				</template>
			</or-drop-down>

			<or-drop-down class="search-panel__item" type="search">
				<template v-slot:toggle="{ active }">
					<or-input
						readonly
						:placeholderWrap="'Cities'"
						icon-before="city"
						icon-after="arrow"
						class="search-panel__input main-type"
						v-model="displayCities"
						:class="{ 'active-input': active, 'have-content': displayCities }"
					/>
				</template>
				<template v-slot:content>
					<SearchList
						class="search-panel__drop-content"
						:regions="filters.city"
						v-model="cities"
					/>
				</template>
			</or-drop-down>

			<or-drop-down class="search-panel__item" type="search">
				<template v-slot:toggle="{ active }">
					<or-input
						readonly
						:placeholderWrap="$t('general.area')"
						icon-before="location"
						icon-after="arrow"
						class="search-panel__input main-type"
						v-model="displayRegions"
						:class="{ 'active-input': active, 'have-content': displayRegions }"
					/>
				</template>
				<template v-slot:content>
					<SearchList
						class="search-panel__drop-content"
						:regions="filters.region"
						v-model="regions"
					/>
				</template>
			</or-drop-down>

			<or-button
				v-if="isShowClearBtn"
				@click="clear"
				kind="solid-border"
				class="search-panel__submit search-panel-btn-clear"
			>
				{{ $t('general.clear') }}
			</or-button>
			<div class="search-panel__item advanced-statuses">
				<ul class="advanced-statuses__list">
					<li
						v-for="item in filters.status"
						:key="item.slug"
						class="advanced-statuses__item"
					>
						<label
							:class="{
								active: statuses.includes(item.slug),
								nonactive:
									item.announcement_count === 0 && !statuses.includes(item.slug)
							}"
						>
							{{ item.title }}
							<input
								type="checkbox"
								v-model="statuses"
								:value="item.slug"
								name="advanced-statuses"
								hidden
							/>
						</label>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script setup>
import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'
import SearchList from '@/components/SearchPanel/SearchList.vue'
import PropertyList from '@/components/SearchPanel/PropertyList.vue'
import CountRoomsBaths from '@/components/SearchPanel/CountRoomsBaths.vue'
import PriceBlock from '@/components/SearchPanel/Price.vue'
import Square from '@/components/SearchPanel/Square.vue'
import { useRoute, useRouter } from 'vue-router'
import { computed, reactive, shallowRef, watch } from 'vue'
import { simbol } from '@/enums/moneyСollections'
import { formatCash, formatNumber } from '@/utils/formatMoney'
import { useStore } from 'vuex'
import { squareSymbols } from '@/enums/areaCollections'
import cloneDeep from 'lodash.clonedeep'
import WiseProjectsList from '@/components/SearchPanel/WiseProjectsList.vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
	type: {
		type: String,
		default: ''
	},
	isShowClearBtn: {
		type: Boolean,
		default: false
	}
})

const emit = defineEmits(['update'])

const route = props.type === 'page' ? useRoute() : reactive({ query: {} })
const router = useRouter()
const store = useStore()
const { t } = useI18n()

const filters = shallowRef()

let _loadFiltersCall
await loadFilters()

const price = computed({
	get() {
		const from = route.query.price_from
		const to = route.query.price_to
		return { from, to }
	},
	set({ from, to }) {
		mutateQueryState({ price_from: from, price_to: to })
	}
})

const priceRange = computed(() => {
	return {
		from: filters.value.price_from[currencyMapper(store.state.activeMoney)],
		to: filters.value.price_to[currencyMapper(store.state.activeMoney)]
	}
})

const area = computed({
	get() {
		const from = route.query.area_from
		const to = route.query.area_to
		return { from, to }
	},
	set({ from, to }) {
		mutateQueryState({ area_from: from, area_to: to })
	}
})

const areaRange = computed(() => {
	return {
		from: filters.value.area_from[store.state.areaUnit],
		to: filters.value.area_to[store.state.areaUnit]
	}
})

const displayPrice = computed(() => {
	let displayString
	if (price.value.from || price.value.to) {
		displayString = `${
			price.value.from
				? simbol[store.state.activeMoney] + formatCash(+price.value.from || 0)
				: 'Min'
		} — ${
			price.value.to
				? simbol[store.state.activeMoney] + formatCash(+price.value.to || 0)
				: 'Max'
		}`
	} else displayString = null
	return displayString
})

const displayArea = computed(() => {
	let displayString
	if (area.value.from || area.value.to) {
		displayString = `${
			area.value.from ? formatNumber(+area.value.from) : 'Min'
		} — ${area.value.to ? formatNumber(+area.value.to) : 'Max'}`
	} else displayString = null
	return displayString
})

const currentSquareSymbol = computed(() => {
	return squareSymbols[store.state.areaUnit]
})

const types = computed({
	get() {
		const ids = route.query.type_id
		return ids?.split(',') ?? []
	},
	set(ids) {
		let queryString
		if (ids.length === 0) {
			queryString = undefined
		} else {
			queryString = ids.toString()
		}
		mutateQueryState({ type_id: queryString })
	}
})

const displayTypes = computed(() => {
	const t = types.value
	const selectedTypes = filters.value.types.filter((type) =>
		t.includes(type.id.toString())
	)
	return selectedTypes.map((itm) => itm.title).join(', ')
})

const bedrooms = computed({
	get() {
		const bedrooms = route.query.bedroom_count
		return bedrooms?.split(',') ?? []
	},
	set(counts) {
		let queryString
		if (counts.length === 0) {
			queryString = undefined
		} else {
			queryString = counts.toString()
		}
		mutateQueryState({ bedroom_count: queryString })
	}
})

const bathrooms = computed({
	get() {
		const bedrooms = route.query.bathroom_count
		return bedrooms?.split(',') ?? []
	},
	set(counts) {
		let queryString
		if (counts.length === 0) {
			queryString = undefined
		} else {
			queryString = counts.toString()
		}
		mutateQueryState({ bathroom_count: queryString })
	}
})

const displayRooms = computed(() => {
	let displayString
	if (bedrooms.value.length + bathrooms.value.length === 0) {
		displayString = undefined
	} else {
		displayString = `${
			bedrooms.value.toSorted().map((bedroom) => {
				if (bedroom.toString() === '0') {
					return t('general.studio')
				} else {
					return bedroom
				}
			}) || 0
		} ${t('general.rooms')};\n ${bathrooms.value.toSorted() || 0} ${t(
			'general.baths'
		)}
    `
	}
	return displayString
})

const regions = computed({
	get() {
		const regions = route.query.region
		return regions?.split(',') ?? []
	},
	set(regions) {
		let queryString
		if (regions.length === 0) {
			queryString = undefined
		} else {
			queryString = regions.toString()
		}
		mutateQueryState({ region: queryString })
	}
})

const displayRegions = computed(() => {
	const r = filters.value.region.filter((region) =>
		regions.value.includes(region.id.toString())
	)
	return r.map((itm) => itm.title).join(', ')
})

const projects = computed({
	get() {
		const projects = route.query.pr_slug
		return projects?.split(',') ?? []
	},
	set(regions) {
		let queryString
		if (regions.length === 0) {
			queryString = undefined
		} else {
			queryString = regions.toString()
		}
		mutateQueryState({ pr_slug: queryString })
	}
})
const displayProjects = computed(() => {
	const p = filters.value.projects.filter((project) =>
		projects.value.includes(project.slug.toString())
	)
	return p.map((itm) => itm.title).join(', ')
})

const cities = computed({
	get() {
		const cities = route.query.city
		return cities?.split(',') ?? []
	},
	set(cities) {
		let queryString
		if (cities.length === 0) {
			queryString = undefined
		} else {
			queryString = cities.toString()
		}
		mutateQueryState({ city: queryString })
	}
})

const displayCities = computed(() => {
	const c = filters.value.city.filter((city) =>
		cities.value.includes(city.id.toString())
	)
	return c.map((itm) => itm.title).join(', ')
})
const statuses = computed({
	get() {
		const statuses = route.query.status
		return statuses?.toString().split(',') ?? []
	},
	set(statuses) {
		let queryString
		if (statuses.length === 0) {
			queryString = undefined
		} else {
			queryString = statuses.toString()
		}
		mutateQueryState({ status: queryString })
	}
})

function currencyMapper(unit) {
	return unit === 'USD' ? 'dollar' : unit.toLowerCase()
}

function mutateQueryState(partialQuery) {
	const query = cloneDeep(route.query)
	for (const [key, value] of Object.entries(partialQuery)) {
		query[key] = value
	}

	if (props.type === 'page') {
		router.push({ query: query })
	} else {
		route.query = query
	}
}

function submit() {
	if (props.type === 'page') {
		emit('update')
	} else {
		router.push({
			name: 'Search',
			params: { lang: store.state.uiLanguage },
			query: route.query
		})
	}
}

function clear() {
	const query = {}
	if (props.type === 'page') {
		router.push({ query: query })
	} else {
		route.query = query
	}
}

async function loadFilters() {
	const timestamp = new Date()
	_loadFiltersCall = timestamp
	const response = await createRequest(requestConfigs.GETSearchFilters, {
		queryPayload: {
			...route.query
		}
	})

	if (timestamp === _loadFiltersCall) {
		filters.value = response.response.result
	}
}

watch(() => route.query, loadFilters, { deep: true })
watch(
	() => store.state.areaUnit,
	(newUnit, oldUnit) => {
		const unitCoefficients = {
			square_meter: 1,
			square_foot: 10.76
		}
		const changeMetric = (value) =>
			(value / unitCoefficients[oldUnit ?? 'square_meter']) *
			unitCoefficients[newUnit ?? 'square_meter']

		const { from, to } = area.value
		area.value = {
			from: from ? Math.floor(changeMetric(from)) : undefined,
			to: to ? Math.ceil(changeMetric(to)) : undefined
		}
	}
)

watch(
	() => store.state.activeMoney,
	async (newUnit, oldUnit) => {
		const response = await createRequest(requestConfigs.GETPlaceCurrency)
		const currency = response.response

		const changeCurrency = (value) =>
			(value / currency[currencyMapper(oldUnit)]) *
			currency[currencyMapper(newUnit)]

		const { from, to } = price.value
		price.value = {
			from: from ? Math.floor(changeCurrency(from)) : undefined,
			to: to ? Math.ceil(changeCurrency(to)) : undefined
		}
	}
)
</script>

<style lang="scss">
.search-panel {
	padding: 3%;
	background-color: white;

	&__input {
		cursor: pointer;
		&.placeholder-wrap {
			position: relative;
			.main-textarea-content {
				display: flex;
				align-items: center;
				padding-top: 14px;
				resize: none;
				overflow: hidden;
				-webkit-line-clamp: 1;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				&::placeholder {
					white-space: pre-line;
					position: absolute;
					top: 50%;
					transform: translate(0, -50%);
					max-width: 60%;
				}
			}
		}
	}
	&.page {
		display: flex;
		gap: 10px;
		justify-content: space-between;
		padding: 0;

		@media (max-width: 768px) {
			flex-direction: column;
			padding-bottom: 20px;
		}

		.search-panel-wrapper {
			width: 100%;
		}
	}
	&.search-panel-home {
		.search-panel-wrapper {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(155px, 1fr));
			grid-gap: 10px;
			height: 100%;
			@media (max-width: 1180px) {
				grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
			}
			.advanced-statuses {
				grid-column: 1 / -1;
				.advanced-statuses__list {
					justify-content: flex-end;
				}
				@media (max-width: 1180px) {
					padding: 10px 0;
					grid-column: unset;
				}
			}

			.button {
				width: 100%;
				grid-row: 1/1;
				grid-column: 5/6;

				@media (max-width: 1180px) {
					grid-row: 8/9;
					grid-column: 1/2;
				}
			}
		}
	}
	&.search-panel-search {
		.search-panel-wrapper {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
			grid-gap: 10px;

			@media (max-width: 1180px) {
				grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
			}

			.advanced-statuses {
				grid-column: 1 / -1;
				@media (max-width: 1180px) {
					padding: 20px 0;
					grid-column: unset;
				}
			}

			.search-panel-btn {
				width: 100%;
				grid-row: 1/1;
				grid-column: 5/6;
				@media (max-width: 1180px) {
					grid-row: 9/9;
					grid-column: 1/2;
				}
			}

			.advanced-statuses__list {
				justify-content: flex-end;

				@media (max-width: 1180px) {
					justify-content: flex-start;
				}
			}
		}
	}
	&__drop-content {
		margin-top: 10px;

		&--last {
			margin-left: -170px;

			@media (max-width: 768px) {
				margin-left: 0;
			}
		}
	}
	&__bottom {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
	&__checkboxes {
		display: flex;
		flex-direction: row;
		align-items: center;
		grid-gap: 10px;
		flex-wrap: wrap;

		@media (max-width: 768px) {
			flex-direction: column;
		}
	}
	& .search-panel-btn-clear {
		grid-column: 5 /6;
		grid-row: 2/ 3;
		@media screen and (max-width: 1180px) {
			grid-column: unset;
			grid-row: unset;
		}
	}
}
</style>

<style lang="scss" scoped>
.advanced-statuses {
	&__list {
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		align-items: center;
	}

	&__item {
		display: flex;

		label {
			position: relative;
			display: block;
			flex-shrink: 0;
			padding: 5px 10px;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			color: #000000;
			background-color: transparent;
			border-radius: 4px;
			cursor: pointer;
			border: 1px solid #c99d56;
			transition: all 0.3s ease-in-out;
			&:hover {
				background-color: #c99d56;
				color: #fff;
			}

			&.active {
				border-color: #c99d56;
				background-color: #c99d56;
				color: #fff;
			}
			&.nonactive {
				border-color: rgba(0, 0, 0, 0.1);
				color: #808080;
				pointer-events: none;
			}

			svg {
				position: absolute;
				right: 12px;
				top: 0;
				bottom: 0;
				margin: auto;
			}
		}
	}
}
</style>
